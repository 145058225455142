import React from 'react'
import styles from './BitrixContainer.module.scss'
import { ReactComponent as Logo } from 'src/assets/images/logo.svg'
import { Button } from 'src/components/Button/Button'
import { Section } from 'src/components/Section/Section'
import { useDispatch } from 'react-redux'
import { openModal } from 'src/store/modals/actionCreators'

const items = ['Настроим под задачи вашей организации и обучим сотрудников', 'Опишем бизнес-процессы и разработаем техническое задание', 'Интегрируем Битрикс24 с внутренними системами', 'Обучим персонал и дадим видеоинструкции', 'Окажем техническую поддержку после внедрения']

export const BitrixContainer = () => {

  const dispatch = useDispatch()

  return (
    <div className="bitrix-page">
      <section className="bitrix-page__intro">
        <div className="container">
          <div className={styles.logo}>
            <Logo />
          </div>
          <h1 className="bitrix-page__title">Внедрение Битрикс24</h1>
          <div className="bitrix-page__text">Настроим под задачи вашей организации&nbsp;<br />и обучим сотрудников.</div>
        </div>
      </section>
      <section className="bitrix-page__section">
        <div className="bitrix-page__container">
          <div className="bitrix-page__features">
            <div className="bitrix-page__heading">Битрикс24 — многофункциональная CRM&#8209;система № 1 в России</div>
            <div className="bitrix-page__features-grid">
              {items.map(item =>
                <div className="bitrix-page__features-item" key={item}>
                  <div className="bitrix-page__features-icon">
                    <svg
                      className="bitrix-page__features-svg"
                      width={48}
                      height={48}
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width={48} height={48} rx={12} fill="url(#paint0_linear_1794_9509)" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.6192 18L18.5459 26.1999L15.3544 22.95L13.9736 24.35L18.5459 29L28 19.4001L26.6192 18ZM22.6664 27.0943L24.5459 29L34 19.4001L32.6192 18L24.5459 26.1999L24.0462 25.6932L22.6664 27.0943Z"
                        fill="#0B0B0B"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1794_9509"
                          x1="-1.5"
                          y1="-2.20779e-06"
                          x2="44.2652"
                          y2="64.7026"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#73F9FF" />
                          <stop offset="0.451158" stopColor="#F3F3F3" />
                          <stop offset={1} stopColor="#FBFF37" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="bitrix-page__features-text">{item}</div>
                </div>
              )}
            </div>
            <div className="bitrix-page__features-btn">
              <Button
                text='Заказать'
                extraClass={styles.button}
                onClick={() => dispatch(openModal('bitrix'))}
              />
            </div>
          </div>
        </div>
      </section>
      <Section>
        <div className="container">
          <div className="bitrix-page__problems">
            <div className="bitrix-page__problems-title">Внедрение Битрикс24 решает проблемы</div>
            <div className="bitrix-page__problems-grid">
              <div className="bitrix-page__problems-item">
                <div className="bitrix-page__problems-bg">
                  <div className="bitrix-page__problems-decor"></div>
                  <div className="bitrix-page__problems-decor"></div>
                  <div className="bitrix-page__problems-decor"></div>
                  <div className="bitrix-page__problems-decor"></div>
                </div>
                <div className="bitrix-page__problems-icon">
                  <svg
                    className="bitrix-page__problems-svg"
                    width={48}
                    height={48}
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width={48} height={48} rx={12} fill="url(#paint0_linear_1793_7847)" />
                    <path
                      d="M25.1498 32.587H21.9998V29.437H25.1498V32.587ZM25.1498 27.5426H21.9998V15.9999H25.1498V27.5426Z"
                      fill="#0B0B0B"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1793_7847"
                        x1="-1.5"
                        y1="-2.20779e-06"
                        x2="44.2652"
                        y2="64.7026"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#73F9FF" />
                        <stop offset="0.451158" stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#FBFF37" />
                      </linearGradient>
                    </defs>
                  </svg>

                </div>
                <div className="bitrix-page__problems-text">Сотрудники не всегда точно разбираются в структуре организации и подчиненности, не понимают, к кому они могут обратиться с вопросами, или не до конца уверены в должностях коллег.</div>
              </div>
              <div className="bitrix-page__problems-item">
                <div className="bitrix-page__problems-bg">
                  <div className="bitrix-page__problems-decor"></div>
                  <div className="bitrix-page__problems-decor"></div>
                  <div className="bitrix-page__problems-decor"></div>
                  <div className="bitrix-page__problems-decor"></div>
                </div>
                <div className="bitrix-page__problems-icon">
                  <svg
                    className="bitrix-page__problems-svg"
                    width={48}
                    height={48}
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width={48} height={48} rx={12} fill="url(#paint0_linear_1793_7847)" />
                    <path
                      d="M25.1498 32.587H21.9998V29.437H25.1498V32.587ZM25.1498 27.5426H21.9998V15.9999H25.1498V27.5426Z"
                      fill="#0B0B0B"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1793_7847"
                        x1="-1.5"
                        y1="-2.20779e-06"
                        x2="44.2652"
                        y2="64.7026"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#73F9FF" />
                        <stop offset="0.451158" stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#FBFF37" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="bitrix-page__problems-text">Руководители не могут добиться необходимой четкости в решении поставленных задач, сроки периодически срываются, а задачи теряются, нет прозрачной приоритетности. В компании происходят глобальные изменения, но не все осведомлены и работают по старым правилам.</div>
              </div>
              <div className="bitrix-page__problems-item">
                <div className="bitrix-page__problems-bg">
                  <div className="bitrix-page__problems-decor"></div>
                  <div className="bitrix-page__problems-decor"></div>
                  <div className="bitrix-page__problems-decor"></div>
                  <div className="bitrix-page__problems-decor"></div>
                </div>
                <div className="bitrix-page__problems-icon">
                  <svg
                    className="bitrix-page__problems-svg"
                    width={48}
                    height={48}
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width={48} height={48} rx={12} fill="url(#paint0_linear_1793_7847)" />
                    <path
                      d="M25.1498 32.587H21.9998V29.437H25.1498V32.587ZM25.1498 27.5426H21.9998V15.9999H25.1498V27.5426Z"
                      fill="#0B0B0B"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1793_7847"
                        x1="-1.5"
                        y1="-2.20779e-06"
                        x2="44.2652"
                        y2="64.7026"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#73F9FF" />
                        <stop offset="0.451158" stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#FBFF37" />
                      </linearGradient>
                    </defs>
                  </svg>

                </div>
                <div className="bitrix-page__problems-text">Процессы не автоматизированы и не управляемы, простая заявка теряется, её обработка затягивается. Как следствие, растет внутреннее недовольство, сотрудники перекладывают вину друг на друга.</div>
              </div>
              <div className="bitrix-page__problems-item">
                <div className="bitrix-page__problems-bg">
                  <div className="bitrix-page__problems-decor"></div>
                  <div className="bitrix-page__problems-decor"></div>
                  <div className="bitrix-page__problems-decor"></div>
                  <div className="bitrix-page__problems-decor"></div>
                </div>
                <div className="bitrix-page__problems-icon">
                  <svg
                    className="bitrix-page__problems-svg"
                    width={48}
                    height={48}
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width={48} height={48} rx={12} fill="url(#paint0_linear_1793_7847)" />
                    <path
                      d="M25.1498 32.587H21.9998V29.437H25.1498V32.587ZM25.1498 27.5426H21.9998V15.9999H25.1498V27.5426Z"
                      fill="#0B0B0B"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1793_7847"
                        x1="-1.5"
                        y1="-2.20779e-06"
                        x2="44.2652"
                        y2="64.7026"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#73F9FF" />
                        <stop offset="0.451158" stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#FBFF37" />
                      </linearGradient>
                    </defs>
                  </svg>

                </div>
                <div className="bitrix-page__problems-text">Рядовым сотрудникам сложно доносить свои идеи по улучшению бизнес-процессов до руководства, нет системы оценки и воплощения лучших идей в жизнь. В компании используется большое количество разрозненных систем с собственными доступами и базами данных. Сотрудники вынуждены вносить информацию в разные системы, помнить пароли и т.д.</div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="bitrix-page__container">
          <div className="bitrix-page__features purple">
            <div className="bitrix-page__heading">Битрикс24 — это набор самых важных и полезных инструментов, которые помогут вам в бизнесе</div>
            <div className="bitrix-page__features-grid">
              <div className="bitrix-page__features-item">
                <div className="bitrix-page__features-icon"><svg className="bitrix-page__features-svg" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1793_7917)">
                    <rect width="48" height="48" rx="12" fill="#0000F2"></rect>
                    <g opacity="0.7" filter="url(#filter0_f_1793_7917)">
                      <circle cx="52" cy="61" r="40" fill="black"></circle>
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" d="M26.6192 18L18.5459 26.1999L15.3544 22.95L13.9736 24.35L18.5459 29L28 19.4001L26.6192 18ZM22.6664 27.0943L24.5459 29L34 19.4001L32.6192 18L24.5459 26.1999L24.0462 25.6932L22.6664 27.0943Z" fill="white"></path>
                  </g>
                  <defs>
                    <filter id="filter0_f_1793_7917" x="-32" y="-23" width="168" height="168" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feflood floodOpacity="0" result="BackgroundImageFix"></feflood>
                      <feblend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feblend>
                      <fegaussianblur stdDeviation="22" result="effect1_foregroundBlur_1793_7917"></fegaussianblur>
                    </filter>
                    <clippath id="clip0_1793_7917">
                      <rect width="48" height="48" rx="12" fill="white"></rect>
                    </clippath>
                  </defs>
                </svg></div>
                <div className="bitrix-page__features-text">Единая клиентская база</div>
              </div>
              <div className="bitrix-page__features-item">
                <div className="bitrix-page__features-icon"><svg className="bitrix-page__features-svg" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1793_7917)">
                    <rect width="48" height="48" rx="12" fill="#0000F2"></rect>
                    <g opacity="0.7" filter="url(#filter0_f_1793_7917)">
                      <circle cx="52" cy="61" r="40" fill="black"></circle>
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" d="M26.6192 18L18.5459 26.1999L15.3544 22.95L13.9736 24.35L18.5459 29L28 19.4001L26.6192 18ZM22.6664 27.0943L24.5459 29L34 19.4001L32.6192 18L24.5459 26.1999L24.0462 25.6932L22.6664 27.0943Z" fill="white"></path>
                  </g>
                  <defs>
                    <filter id="filter0_f_1793_7917" x="-32" y="-23" width="168" height="168" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feflood floodOpacity="0" result="BackgroundImageFix"></feflood>
                      <feblend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feblend>
                      <fegaussianblur stdDeviation="22" result="effect1_foregroundBlur_1793_7917"></fegaussianblur>
                    </filter>
                    <clippath id="clip0_1793_7917">
                      <rect width="48" height="48" rx="12" fill="white"></rect>
                    </clippath>
                  </defs>
                </svg></div>
                <div className="bitrix-page__features-text">Управление продажами</div>
              </div>
              <div className="bitrix-page__features-item">
                <div className="bitrix-page__features-icon"><svg className="bitrix-page__features-svg" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1793_7917)">
                    <rect width="48" height="48" rx="12" fill="#0000F2"></rect>
                    <g opacity="0.7" filter="url(#filter0_f_1793_7917)">
                      <circle cx="52" cy="61" r="40" fill="black"></circle>
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" d="M26.6192 18L18.5459 26.1999L15.3544 22.95L13.9736 24.35L18.5459 29L28 19.4001L26.6192 18ZM22.6664 27.0943L24.5459 29L34 19.4001L32.6192 18L24.5459 26.1999L24.0462 25.6932L22.6664 27.0943Z" fill="white"></path>
                  </g>
                  <defs>
                    <filter id="filter0_f_1793_7917" x="-32" y="-23" width="168" height="168" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feflood floodOpacity="0" result="BackgroundImageFix"></feflood>
                      <feblend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feblend>
                      <fegaussianblur stdDeviation="22" result="effect1_foregroundBlur_1793_7917"></fegaussianblur>
                    </filter>
                    <clippath id="clip0_1793_7917">
                      <rect width="48" height="48" rx="12" fill="white"></rect>
                    </clippath>
                  </defs>
                </svg></div>
                <div className="bitrix-page__features-text">Работа с задачами и проектами</div>
              </div>
              <div className="bitrix-page__features-item">
                <div className="bitrix-page__features-icon"><svg className="bitrix-page__features-svg" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1793_7917)">
                    <rect width="48" height="48" rx="12" fill="#0000F2"></rect>
                    <g opacity="0.7" filter="url(#filter0_f_1793_7917)">
                      <circle cx="52" cy="61" r="40" fill="black"></circle>
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" d="M26.6192 18L18.5459 26.1999L15.3544 22.95L13.9736 24.35L18.5459 29L28 19.4001L26.6192 18ZM22.6664 27.0943L24.5459 29L34 19.4001L32.6192 18L24.5459 26.1999L24.0462 25.6932L22.6664 27.0943Z" fill="white"></path>
                  </g>
                  <defs>
                    <filter id="filter0_f_1793_7917" x="-32" y="-23" width="168" height="168" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feflood floodOpacity="0" result="BackgroundImageFix"></feflood>
                      <feblend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feblend>
                      <fegaussianblur stdDeviation="22" result="effect1_foregroundBlur_1793_7917"></fegaussianblur>
                    </filter>
                    <clippath id="clip0_1793_7917">
                      <rect width="48" height="48" rx="12" fill="white"></rect>
                    </clippath>
                  </defs>
                </svg></div>
                <div className="bitrix-page__features-text">Автоматизация маркетинга</div>
              </div>
              <div className="bitrix-page__features-item">
                <div className="bitrix-page__features-icon"><svg className="bitrix-page__features-svg" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1793_7917)">
                    <rect width="48" height="48" rx="12" fill="#0000F2"></rect>
                    <g opacity="0.7" filter="url(#filter0_f_1793_7917)">
                      <circle cx="52" cy="61" r="40" fill="black"></circle>
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" d="M26.6192 18L18.5459 26.1999L15.3544 22.95L13.9736 24.35L18.5459 29L28 19.4001L26.6192 18ZM22.6664 27.0943L24.5459 29L34 19.4001L32.6192 18L24.5459 26.1999L24.0462 25.6932L22.6664 27.0943Z" fill="white"></path>
                  </g>
                  <defs>
                    <filter id="filter0_f_1793_7917" x="-32" y="-23" width="168" height="168" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feflood floodOpacity="0" result="BackgroundImageFix"></feflood>
                      <feblend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feblend>
                      <fegaussianblur stdDeviation="22" result="effect1_foregroundBlur_1793_7917"></fegaussianblur>
                    </filter>
                    <clippath id="clip0_1793_7917">
                      <rect width="48" height="48" rx="12" fill="white"></rect>
                    </clippath>
                  </defs>
                </svg></div>
                <div className="bitrix-page__features-text">Омниканальные коммуникации</div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="container">
          <h1 className="bitrix-page__title">Тарифы</h1>
          <div className="bitrix-page__text">Облако на год</div>
          <div className="bitrix-page__price">
            <div className="bitrix-page__price-grid">
              <div className="bitrix-page__price-rate">
                <div className="bitrix-page__price-top">
                  <div className="bitrix-page__price-inner">
                    <div className="bitrix-page__price-title">Малому бизнесу</div>
                    <div className="bitrix-page__price-text">Для эффективной работы небольших компаний и отделов продаж</div>
                  </div>
                  <div className="bitrix-page__price-list">
                    <div className="bitrix-page__price-item">
                      <div className="bitrix-page__rate-item">
                        <div className="bitrix-page__rate-heading">Базовый</div>
                        <div className="bitrix-page__rate-users">
                          <div className="bitrix-page__rate-icon"><svg className="bitrix-page__rate-svg" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="24 / basic / user">
                              <path id="icon" fillRule="evenodd" clipRule="evenodd" d="M7.99996 15.3334C3.94987 15.3334 0.666626 12.0501 0.666626 8.00002C0.666626 3.94993 3.94987 0.666687 7.99996 0.666687C12.05 0.666687 15.3333 3.94993 15.3333 8.00002C15.3333 12.0501 12.05 15.3334 7.99996 15.3334ZM12.933 11.4165C13.6057 10.4469 14 9.2695 14 8.00002C14 4.68631 11.3137 2.00002 7.99996 2.00002C4.68625 2.00002 1.99996 4.68631 1.99996 8.00002C1.99996 9.2695 2.39421 10.4469 3.06696 11.4165C3.81726 10.4254 5.58036 10 7.99996 10C10.4196 10 12.1827 10.4254 12.933 11.4165ZM11.9764 12.4932C11.7813 11.7916 10.3795 11.3334 7.99996 11.3334C5.62037 11.3334 4.21863 11.7916 4.0235 12.4932C5.08216 13.4308 6.47459 14 7.99996 14C9.52533 14 10.9178 13.4308 11.9764 12.4932ZM7.99996 4.00002C6.38603 4.00002 5.33329 5.17053 5.33329 6.66669C5.33329 8.9516 6.5072 10 7.99996 10C9.47868 10 10.6666 8.98643 10.6666 6.80002C10.6666 5.28107 9.60942 4.00002 7.99996 4.00002ZM6.66663 6.66669C6.66663 8.17953 7.2121 8.66669 7.99996 8.66669C8.7851 8.66669 9.33329 8.19895 9.33329 6.80002C9.33329 5.96697 8.8104 5.33335 7.99996 5.33335C7.15579 5.33335 6.66663 5.87724 6.66663 6.66669Z" fill="#0B0B0B"></path>
                            </g>
                          </svg></div>
                          <div className="bitrix-page__rate-text">5 пользователей</div>
                        </div>
                      </div>
                      <div className="bitrix-page__rate-item">
                        <div className="bitrix-page__rate-heading">1 990 ₽/мес<span className="bitrix-page__rate-old"> 2 490 ₽/мес</span></div>
                        <div className="bitrix-page__rate-label">Скидка 20%</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bitrix-page__rate-bot">
                  <Button
                    text={'Выбрать'}
                    extraClass={styles.btn}
                    onClick={() => dispatch(openModal('bitrix'))}
                  />
                </div>
              </div>
              <div className="bitrix-page__price-rate">
                <div className="bitrix-page__price-top">
                  <div className="bitrix-page__price-inner">
                    <div className="bitrix-page__price-title">Среднему бизнесу</div>
                    <div className="bitrix-page__price-text">Автоматизировать рутинные задачи, чтобы высвобождать ресурсы для решения задач поважнее</div>
                  </div>
                  <div className="bitrix-page__price-list">
                    <div className="bitrix-page__price-item">
                      <div className="bitrix-page__rate-item">
                        <div className="bitrix-page__rate-heading">Стандартный</div>
                        <div className="bitrix-page__rate-users">
                          <div className="bitrix-page__rate-icon"><svg className="bitrix-page__rate-svg" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="24 / basic / user">
                              <path id="icon" fillRule="evenodd" clipRule="evenodd" d="M7.99996 15.3334C3.94987 15.3334 0.666626 12.0501 0.666626 8.00002C0.666626 3.94993 3.94987 0.666687 7.99996 0.666687C12.05 0.666687 15.3333 3.94993 15.3333 8.00002C15.3333 12.0501 12.05 15.3334 7.99996 15.3334ZM12.933 11.4165C13.6057 10.4469 14 9.2695 14 8.00002C14 4.68631 11.3137 2.00002 7.99996 2.00002C4.68625 2.00002 1.99996 4.68631 1.99996 8.00002C1.99996 9.2695 2.39421 10.4469 3.06696 11.4165C3.81726 10.4254 5.58036 10 7.99996 10C10.4196 10 12.1827 10.4254 12.933 11.4165ZM11.9764 12.4932C11.7813 11.7916 10.3795 11.3334 7.99996 11.3334C5.62037 11.3334 4.21863 11.7916 4.0235 12.4932C5.08216 13.4308 6.47459 14 7.99996 14C9.52533 14 10.9178 13.4308 11.9764 12.4932ZM7.99996 4.00002C6.38603 4.00002 5.33329 5.17053 5.33329 6.66669C5.33329 8.9516 6.5072 10 7.99996 10C9.47868 10 10.6666 8.98643 10.6666 6.80002C10.6666 5.28107 9.60942 4.00002 7.99996 4.00002ZM6.66663 6.66669C6.66663 8.17953 7.2121 8.66669 7.99996 8.66669C8.7851 8.66669 9.33329 8.19895 9.33329 6.80002C9.33329 5.96697 8.8104 5.33335 7.99996 5.33335C7.15579 5.33335 6.66663 5.87724 6.66663 6.66669Z" fill="#0B0B0B"></path>
                            </g>
                          </svg></div>
                          <div className="bitrix-page__rate-text">50 пользователей</div>
                        </div>
                      </div>
                      <div className="bitrix-page__rate-item">
                        <div className="bitrix-page__rate-heading">5 590 ₽/мес<span className="bitrix-page__rate-old">6 990 ₽/мес</span></div>
                        <div className="bitrix-page__rate-label">Скидка 20%</div>
                      </div>
                    </div>
                    <div className="bitrix-page__price-item">
                      <div className="bitrix-page__rate-item">
                        <div className="bitrix-page__rate-heading">Профессиональный</div>
                        <div className="bitrix-page__rate-users">
                          <div className="bitrix-page__rate-icon"><svg className="bitrix-page__rate-svg" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="24 / basic / user">
                              <path id="icon" fillRule="evenodd" clipRule="evenodd" d="M7.99996 15.3334C3.94987 15.3334 0.666626 12.0501 0.666626 8.00002C0.666626 3.94993 3.94987 0.666687 7.99996 0.666687C12.05 0.666687 15.3333 3.94993 15.3333 8.00002C15.3333 12.0501 12.05 15.3334 7.99996 15.3334ZM12.933 11.4165C13.6057 10.4469 14 9.2695 14 8.00002C14 4.68631 11.3137 2.00002 7.99996 2.00002C4.68625 2.00002 1.99996 4.68631 1.99996 8.00002C1.99996 9.2695 2.39421 10.4469 3.06696 11.4165C3.81726 10.4254 5.58036 10 7.99996 10C10.4196 10 12.1827 10.4254 12.933 11.4165ZM11.9764 12.4932C11.7813 11.7916 10.3795 11.3334 7.99996 11.3334C5.62037 11.3334 4.21863 11.7916 4.0235 12.4932C5.08216 13.4308 6.47459 14 7.99996 14C9.52533 14 10.9178 13.4308 11.9764 12.4932ZM7.99996 4.00002C6.38603 4.00002 5.33329 5.17053 5.33329 6.66669C5.33329 8.9516 6.5072 10 7.99996 10C9.47868 10 10.6666 8.98643 10.6666 6.80002C10.6666 5.28107 9.60942 4.00002 7.99996 4.00002ZM6.66663 6.66669C6.66663 8.17953 7.2121 8.66669 7.99996 8.66669C8.7851 8.66669 9.33329 8.19895 9.33329 6.80002C9.33329 5.96697 8.8104 5.33335 7.99996 5.33335C7.15579 5.33335 6.66663 5.87724 6.66663 6.66669Z" fill="#0B0B0B"></path>
                            </g>
                          </svg></div>
                          <div className="bitrix-page__rate-text">100 пользователей</div>
                        </div>
                      </div>
                      <div className="bitrix-page__rate-item">
                        <div className="bitrix-page__rate-heading">11 190 ₽/мес<span className="bitrix-page__rate-old">13 990 ₽/мес</span></div>
                        <div className="bitrix-page__rate-label">Скидка 20%</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bitrix-page__rate-bot">
                  <Button
                    text={'Выбрать'}
                    extraClass={styles.btn}
                    onClick={() => dispatch(openModal('bitrix'))}
                  />
                </div>
              </div>
              <div className="bitrix-page__price-rate">
                <div className="bitrix-page__price-top">
                  <div className="bitrix-page__price-inner">
                    <div className="bitrix-page__price-title">Крупному бизнесу</div>
                    <div className="bitrix-page__price-text">Чтобы снижать издержки, контролировать процессы, сохранять лидерство</div>
                  </div>
                  <div className="bitrix-page__price-list">
                    <div className="bitrix-page__price-item">
                      <div className="bitrix-page__rate-item">
                        <div className="bitrix-page__rate-heading">Энтерпрайз</div>
                        <div className="bitrix-page__rate-users">
                          <div className="bitrix-page__rate-icon"><svg className="bitrix-page__rate-svg" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="24 / basic / user">
                              <path id="icon" fillRule="evenodd" clipRule="evenodd" d="M7.99996 15.3334C3.94987 15.3334 0.666626 12.0501 0.666626 8.00002C0.666626 3.94993 3.94987 0.666687 7.99996 0.666687C12.05 0.666687 15.3333 3.94993 15.3333 8.00002C15.3333 12.0501 12.05 15.3334 7.99996 15.3334ZM12.933 11.4165C13.6057 10.4469 14 9.2695 14 8.00002C14 4.68631 11.3137 2.00002 7.99996 2.00002C4.68625 2.00002 1.99996 4.68631 1.99996 8.00002C1.99996 9.2695 2.39421 10.4469 3.06696 11.4165C3.81726 10.4254 5.58036 10 7.99996 10C10.4196 10 12.1827 10.4254 12.933 11.4165ZM11.9764 12.4932C11.7813 11.7916 10.3795 11.3334 7.99996 11.3334C5.62037 11.3334 4.21863 11.7916 4.0235 12.4932C5.08216 13.4308 6.47459 14 7.99996 14C9.52533 14 10.9178 13.4308 11.9764 12.4932ZM7.99996 4.00002C6.38603 4.00002 5.33329 5.17053 5.33329 6.66669C5.33329 8.9516 6.5072 10 7.99996 10C9.47868 10 10.6666 8.98643 10.6666 6.80002C10.6666 5.28107 9.60942 4.00002 7.99996 4.00002ZM6.66663 6.66669C6.66663 8.17953 7.2121 8.66669 7.99996 8.66669C8.7851 8.66669 9.33329 8.19895 9.33329 6.80002C9.33329 5.96697 8.8104 5.33335 7.99996 5.33335C7.15579 5.33335 6.66663 5.87724 6.66663 6.66669Z" fill="#0B0B0B"></path>
                            </g>
                          </svg></div>
                          <div className="bitrix-page__rate-text">от 250 пользователей</div>
                        </div>
                      </div>
                      <div className="bitrix-page__rate-item">
                        <div className="bitrix-page__rate-heading">
                          от 27 190 ₽/мес
                          {/* <span className="bitrix-page__rate-old">33 990 ₽/мес</span> */}
                        </div>
                        <div className="bitrix-page__rate-label">Скидка 20%</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bitrix-page__rate-bot">
                  <Button
                    text={'Выбрать'}
                    extraClass={styles.btn}
                    onClick={() => dispatch(openModal('bitrix'))}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  )
}
